import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import NavBar from './components/navbar/NavBar';
import About from './components/about/About';
import './App.css'
import './Colors.css'
import OnlinePage from './components/videos/online/OnlinePage';
import EventPage from './components/videos/online/EventPage';
import EventForm from './components/videos/online/EventForm';
import Vod from './components/videos/vod/Vod';
import VideoPage from './components/videos/vod/VideoPage';
import VideoPageBuy from './components/videos/vod/VideoPageBuy';
import EventCountDownPage from './components/videos/online/EventCountDownPage';
import Account from './components/account/Account';
import Buys from './components/account/Buys';
import AdminPanel from './components/admin/AdminPanel';
import { getEvents, getUser, getUserData, getVideos } from './assets/DataUtils';
import ManageEvent from './components/videos/online/ManageEvent';
import ThankYou from './components/thank-you/ThankYou';
import Home from './components/home/Home';
import Login from './components/Login';
import { hasEvent } from './assets/Utils';
import { events } from './assets/data/events';
import { videos } from './assets/data/videos';

const App = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    user: null,
    events,
    videos,
  })


  const getStoredUser = async () => {
    if (!state.user?.id) {
      let stored_user = await localStorage.getItem("pedagogy_user");
      if (stored_user) {
        let user_data = JSON.parse(stored_user);
        user_data = await getUserData(user_data.id)
        setState({ ...state, user: user_data });
      } else {
        setState({ ...state, user: null });
      }
    }
  }

  const setupRunner = async () => {
    await getStoredUser();
  }


  useEffect(() => {
    setupRunner();
  }, [])

  // if (!window.location.pathname.includes('admin') && !window.location.pathname.includes('thank_you')) {
  //   if (!state.user?.admin && process.env.NODE_ENV === 'production') {
  //     return <div className='App'>
  //       <div className='text-center'>
  //         <div className='p-5 fw-bold fs-3'>
  //           <div className='mt-5'>האתר יעלה בקרוב... שווה לחכות</div>
  //         </div>
  //       </div>
  //     </div>
  //   }
  // }

  // if(!state?.events) {
  //   return <div>טוען</div>
  // }
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className='App'>
        <NavBar state={state} setState={setState} />
        {/* <Router> */}
        <div className='main-content rtl'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/online" element={<OnlinePage state={state} setState={setState} />} />
            <Route path="/online/:event_id" element={<EventPage state={state} setState={setState} />} />
            <Route path="/online/:event_id/enter" element={<EventCountDownPage state={state} setState={setState} />} />
            <Route path="/online/:event_id/register" element={<EventForm state={state} setState={setState} />} />
            <Route path="/vod" element={<Vod state={state} setState={setState} />} />
            <Route path="/vod/events/:event_id" element={<VideoPage state={state} setState={setState} />} />
            <Route path="/vod/:video_id" element={<VideoPage state={state} setState={setState} />} />
            <Route path="/vod/:video_id/info" element={<VideoPage state={state} setState={setState} info={true} />} />
            <Route path="/vod/:video_id/buy" element={<VideoPageBuy state={state} setState={setState} />} />
            <Route path="/thank_you/:origin" element={<ThankYou state={state} setState={setState} navigate={navigate} />} />
            {/* <Route path="/thank_you" element={<ThankYou state={state} setState={setState} navigate={navigate} />} /> */}
            <Route path="/account/buys" element={<Buys state={state} setState={setState} />} />
            <Route path="/account" element={<Account state={state} setState={setState} />} />
            <Route path="/blog" element={<div>Blog</div>} />
            {state.user?.admin && <Route path="/admin/new-event" element={<ManageEvent state={state} setState={setState} />} />}
            {state.user?.admin && <Route path="/admin/edit-event/:eventId" element={<ManageEvent state={state} setState={setState} />} />}
            {state.user?.admin && <Route path="/admin" element={<AdminPanel state={state} setState={setState} />} />}
            <Route path="/*" element={<div>Page Not Found</div>} />
          </Routes>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
