import moment from 'moment';
// import online_ai from ;
// import online_canva1 from '';
// import online_canva2 from '/images/events/online_canva2.png';
// import online_chat from '/images/events/online_chat.png';

export const events = [
  
  {
    id: 'online_chat_12.09.2023',
    title: 'בינה מלאכותית - chatGPT',
    intro: `העלו את ההוראה שלך לשלב הבא עם הכשרה מתקדמת של צ'טבוטים! צלולו עמוק לתוך עולם הצ'אטבוטים, כולל יצירת תוכן אוטומטית מונעת בינה מלאכותית, מצגות דינמיות, עריכת וידאו ועוד. השיגו מיומנויות מעשיות כדי לשפר את חומרי ההוראה שלכם במהירות. הפכו למומחים בצ'אטבוטים וחוללו מהפכה בחינוך.`,
    date: "12.09.2023",
    price: 45,
    time: '20:00',
    html: `<div>
    <p style="font-size: 18px; color: #003366; font-weight: bold;">
      צ'אט GPT הוא <span class="fw-bold" style="color: #ff9900;">אחד הכלים המתקדמים והמרשימים</span> ביותר שפותחו בתחום הבינה המלאכותית.
    </p>
    <p style="font-size: 18px; color: #333;">
      בהדרכה נחשף ל<span class="fw-bold" style="color: #ff9900;">יכולות המדהימות של הצ'אט</span>, נלמד ונכיר סוגים שונים של צ'אטים ונראה כיצד נוכל לשלב כלי זה בעבודת ההוראה היומיומית.
    </p>
    <p style="font-size: 18px; color: #333;">
      נכיר את הטכנולוגיה שמאחורי צ'אט GPT, וניישם את היכולות שלו <span class="fw-bold" style="color: #ff9900;">כעזר וירטואלי בכיתה</span> ונתרשם מהיכולת המרשימה שלו בהבנת שפה <span class="fw-bold" style="color: #ff9900;">וייצור טקסט באופן עצמאי</span>.
    </p>
    <p style="font-size: 18px; color: #333;">
      נתמקד ביכולת של הצ'אט לסייע לעובדי הוראה ביצרת:<span class="fw-bold" style="color: #ff9900;"> מערכי שיעור, משחקים, יחידות הוראה, לוחות קיר, ממני אלייך, שאלות, הערכות ועוד</span>.
    </p>
    <p style="font-size: 18px; color: #333;">
      בסוף ההדרכה תצאו מצוידים ב<span class="fw-bold" style="color: #ff9900;">ארגז כלים מקיף</span> שיכיל כל מה שאתם צריכים על מנת להפוך את ההוראה שלכם לאיכותית, מעניינת ומותאמת יותר עבור התלמידים שלכם באמצעות שימוש יעיל ומושכל בצ'אט.
    </p>
    <p style="font-size: 18px; color: #333;" class="fw-bold">
      אז אתם מוכנים להכיר טכנולוגיה חדשנית להוראה? <span class="fw-bold" style="color: #ff9900;">הרשמו להדרכה הקרובה</span> והצטרפו אלי לעתיד בחינוך.
    </p>
  </div>
  `,
    duration: 90,
    image_url: '/images/events/online_chat.png',
    active: true,
    published_datetime: '20:00',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    buy_url: "https://secure.cardcom.solutions/e/14v4fRQgcEeUc8uGuUROEw",

  },
  {
    id: 'online_canva2_13.09.2023',
    title: 'קנבה (CANVA) למתקדמים',
    intro: `האם אתם מכירים את Canva ורוצים להעמיק את הידע שלכם ביצירת עיצובים מקצועיים? מרגישים שהגיע הזמן להעלות את רמת ההוראה שלכם לשלב הבא? הגעתם למקום הנכון. בהכשרת Canva המתקדמת שלי, נרחיב את הידע שלכם ב - Canva. נלמד נושאים כמו יצירת תוכן אוטומטית בסיוע בינה מלאכותית, מצגות דינמיות, יצירת וידאו ו-GIF ועוד. הצטרפו אליי, הצטיידו בכלים ומיומנויות מתקדמים, וקחו את התוכן החזותי שלכם לשלב הבא בחינוך`,
    date: "13.09.2023",
    price: 45,
    time: '20:00',
    html: `<div>
    <p style="font-size: 18px;">
      מכירים את קנבה ומעוניינים להעמיק את הידע שלכם <span style="font-weight: bold; color: #003366;">ביצירת עיצובים מקצועיים?</span>
      מרגישים שהגיע הזמן לעלות את <span style="font-weight: bold; color: #003366;">רמת ההוראה שלכם לשלב הבא?</span>
      <span style="font-weight: bold; color: #003366;">אז הגעתם למקום הנכון.</span>
    </p>
    <p style="font-size: 18px;">
      בהדרכה של <span style="font-weight: bold; color: #003366;">קנבה מתקדמים</span> נרחיב את הידע שלכם באתר <span style="font-weight: bold; color: #003366;">קנבה</span>, נלמד ונכיר טכניקות מתקדמות הכוללות שימוש בבינה מלאכותית בקנבה <span style="font-weight: bold; color: #ff9900;">ליצירה אוטומטית של חומרי הוראה ומצגות בקליק. עריכת סרטונים ותוכן וידאו, יצירת מדבקות וגיפים לוואטסאפ ועוד.</span>
    </p>
    <p style="font-size: 18px;">
      בואו לרכוש כלים וכישורים נוספים ליצירת עיצובים מתקדמים שיאפשרו לכם ליצור חומרי למידה מרהיבים ברמה גבוהה יותר ובצורה מהירה ויעילה יותר.
    </p>
    <p style="font-size: 18px;font-weight: bold;">
      הצטרפו אליי להדרכה הבאה ונשדרג יחד את התוכן הוויזואלי שלכם לרמה הבאה.
    </p>
  </div>
  `,
    duration: 90,
    active: true,
    image_url: '/images/events/online_canva2.png',
    published_datetime: '20:00',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    buy_url: "https://secure.cardcom.solutions/e/BU5Gr7oDv02kDh9i5XHSA",
  },
  {
    id: 'online_canva1_19.09.2023',
    title: 'קנבה (CANVA) למתחילים',
    intro: `קחו את חומרי הלימוד שלכם לרמה הבאה עם עיצובים מדהימים, אפילו ללא כישורי עיצוב גרפי! גלו את Canva, הפלטפורמה האולטימטיבית ליצירת עיצובים מקצועיים ללא מאמץ. למדו כיצד ליצור חומרים חינוכיים מגוונים כמו מצגות, דפי עבודה ופוסטרים. לימדו כיצד ליצור חומרים חינוכיים מגוונים כמו מצגות, דפי עבודה ופוסטרים. שדרגו את ההוראה שלכם עם תוכן מרתק חזותית.`,
    date: "19.09.2023",
    price: 45,
    time: '20:00',
    html: `<div>
    <p style="font-size: 18px; color: #003366; font-weight: bold;">
      האם אתם מתקשים ליצור חומרי למידה וויזואליים מרשימים ללא ידע בעיצוב גרפי?
    </p>
    <p style="font-size: 18px; color: #ff9900;">
      הגיע הזמן להכיר את קנבה!
    </p>
    <p style="font-size: 18px; color: #333;">
      קנבה הוא אתר אינטרנט המאפשר יצירת עיצובים מקצועיים בקלות <span class="fw-bold" style="color: #ff9900;">ללא צורך בידע מוקדם. ולכם המורים מגיעה גרסת הפרו ב-ח-י-נ-ם!</span>
    </p>
    <p style="font-size: 18px; color: #333;">
      בהדרכה זו נלמד יחד כיצד <span class="fw-bold" style="color: #ff9900;">ליצור חומרי למידה וויזואליים</span>, נתמקד ביצירת חומרים מגוונים כגון <span class="fw-bold" style="color: #ff9900;">מצגות, דפי עבודה, פוסטרים ועוד.</span> נכיר את ממשק האתר, נבחן <span class="fw-bold" style="color: #ff9900;">טמפלטים ועיצובים מוכנים ונלמד טיפים וטריקים לעבודה מהירה ויעילה</span> בקנבה.
    </p>
    <p style="font-size: 18px; color: #333;">
      בנוסף, אתן בהדרכה חשיפה ל<span class="fw-bold" style="color: #ff9900;">קנבה (AI)</span> ואראה איך קנבה עושה שימוש בבינה מלאכותית שמסייעת ליצור עיצובים מגוונים באופן מהיר, חכם ומותאם אישית.
    </p>
    <p style="font-size: 18px; color: #333;">
      בהדרכה אצייד אתכם בכלים מעשיים ליצירה של חומרי למידה מרהיבים בקלילות ובמהירות בהם <span class="fw-bold" style="color: #ff9900;">תוכלו לעשות שימוש כבר מחר בכיתה!</span>
    </p>
    <p style="font-size: 18px; color: #ff9900;" class="fw-bold">
      וכל זה ללא ידע קודם בתחום הגרפיקה.
    </p>
    <p style="font-size: 18px; color: #333;" class="fw-bold">
      הצטרפו אלי להדרכת קנבה למתחילים הקרובה ותהפכו את השיעורים שלכם לוויזואליים ומעניינים יותר.
    </p>
  </div>
  `,
    duration: 90,
    active: true,
    image_url: '/images/events/online_canva1.png',
    published_datetime: '20:00',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    buy_url: "https://secure.cardcom.solutions/e/R7eFhDEjUeXtxoc2FIoEA",
  },
  {
    id: 'online_ai_20.09.2023',
    title: 'בינה מלאכותית',
    intro: `גלו את הפוטנציאל של בינה מלאכותית בחינוך! חקרו את העולם המרתק של בינה מלאכותית והשפעתה הטרנספורמטיבית על ההוראה. הצטרפו אלינו כדי לרתום את הפתרונות היצירתיים של בינה מלאכותית ולספק סיוע מותאם אישית לתלמידים. העצימו את ההוראה שלכם עם כלי בינה מלאכותית והישארו בקידמת החינוך.`,
    date: "20.09.2023",
    price: 45,
    time: '20:00',
    html: `<div>
    <p style="font-size: 18px; color: #333;">בינה מלאכותית יכולה לעשות מהפכה בעולם החינוך, אך מורים רבים <span style="font-weight: bold; color: #003366;">עדיין לא מכירים את הפוטנציאל הגלום</span> בה.</p>
    <p style="font-size: 18px; color: #333;">אז אם שמעת על ״בינה מלאכותית״ אך מעולם לא הבנת באמת מה זה אומר, הגיע הזמן לגלות כיצד בינה מלאכותית <span style="font-weight: bold; color: #003366;">יכולה לשנות את עולם החינוך.</span></p>
    <p style="font-size: 18px; color: #333;">בהדרכה נחשף לעולם המרתק של בינה מלאכותית בחינוך.</p>
    <p style="font-size: 18px; color: #333;">נבין מהי בינה מלאכותית ומהם העקרונות שעומדים מאחוריה.</p>
    <p style="font-size: 18px; color: #333;">נכיר דרכים יצירתיות שבהן היא יכולה <span style="font-weight: bold; color: #003366;">לשפר את חווית הלמידה וההוראה</span>.</p>
    <p style="font-size: 18px; color: #333;">נלמד כיצד ניתן להשתמש בבינה מלאכותית על מנת <span style="font-weight: bold; color: #003366;">לספק לתלמידים סיוע מותאם אישית</span>.</p>
    <p style="font-size: 18px; color: #333;">נגלה כיצד בינה מלאכותית מאפשרת לנו <span style="font-weight: bold; color: #003366;">לבנות חומרי לימוד דינמיים המותאמים לצרכיו הייחודיים של כל לומד</span>.</p>
    <p style="font-size: 18px; color: #333;">נבין גם כיצד ניתן להשתמש בבינה מלאכותית<span style="font-weight: bold; color: #003366;">כדי להנגיש חינוך לתלמידים עם מוגבלויות.</span> </p>
    <p style="font-size: 18px; color: #333;">בנוסף נתנסה בפועל במגוון <span style="font-weight: bold; color: #003366;">כלים חינמיים מבוססי בינה מלאכותית שכל מורה יכול להתחיל להשתמש בהם מיידית בכיתה.</span></p>
    <p style="font-size: 18px; color: #333;">תצאו מההדרכה עם <span style="font-weight: bold; color: #003366;">ערכת כלים מעשית</span> ליישום בינה מלאכותית בעבודתכם.</p>
    <p style="font-size: 18px; color: #333;" class="fw-bold">אז אם מעניין אותך להבין טוב יותר כיצד בינה מלאכותית משנה את פני החינוך – זה הזמן להצטרף להדרכה הקרובה!</p>
  </div>
    
      `,
    duration: 90,
    image_url: '/images/events/online_ai.png',
    active: true,
    published_datetime: '20:00',
    video_url: '',
    buy_url: "https://secure.cardcom.solutions/e/mZrBBY0xH0rPvy5du1D8g",
  },
  

  // Add more events here
];
